import "./index.css";

function App() {
  return (
    <div class="landing-page">
      <div class="header">
        <h1>This Domain is For Sale</h1>
        <p>This premium domain is available for purchase.</p>
      </div>
      <div class="contact-info">
        <p>
          If you are interested in purchasing this domain, please contact us at:
        </p>
        <a href="domaains@gmail.com">domaains@gmail.com</a>
      </div>
      <div class="footer">
        <p>For more details, please contact us at the email address above.</p>
      </div>
    </div>
  );
}

export default App;
